import { useState, useEffect } from 'react';
import './CookieConsent.css';

const CookieConsent = () => {
    const [consentGiven, setConsentGiven] = useState(false);

    useEffect(() => {
        const consent = localStorage.getItem('cookieConsent');
        if (consent === 'true') {
            setConsentGiven(true);
        }
    }, []);

    const handleConsent = () => {
        localStorage.setItem('cookieConsent', 'true');
        setConsentGiven(true);
    };

    if (!consentGiven) {
        return (
            <div className="cookie-consent-container">
                <div className="cookie-consent-content">
                    <p className="cookie-consent-text">
                        Vi använder cookies för att förbättra din upplevelse på vår webbplats.{' '}
                        <a href="/privacy-policy" target="_blank" rel="noopener noreferrer" className="cookie-consent-link">
                            Läs mer
                        </a>{' '}
                        om hur vi använder cookies och hur du kan kontrollera dem genom att klicka på "Inställningar för cookies". Genom att fortsätta använda denna webbplats samtycker du till vår användning av cookies.
                    </p>
                    <button className="cookie-consent-button" onClick={handleConsent}>Jag förstår</button>
                </div>
            </div>
        );
    }

    return null;
};

export default CookieConsent;
