import React, { useState, useRef } from 'react';
import { Ingredient, Meal, OrderMeal, ProductVariant } from './types';
import { useMealContext } from './MealContext';
import './OrderMealIngredientModal.css';

const OrderMealIngredientModal: React.FC<{ meal: Meal }> = ({ meal }) => {
    const { restaurant, selectedMeals, setSelectedMeals, getBranding } = useMealContext();
    const [ingredients] = useState<Ingredient[]>(JSON.parse(JSON.stringify(restaurant?.ingredients.filter(x => !meal.ingredients.some(y => y.id === x.id) ?? []))));
    const [ingredientsToRemove, setIngredientsToRemove] = useState<Ingredient[]>([])
    const [ingredientsToAdd, setIngredientsToAdd] = useState<Ingredient[]>([])
    const [selectedVariants, setSelectedVariants] = useState<ProductVariant[]>(() => {
        return meal.productVariants.map(variantGroup => variantGroup.variants[0] ?? '');
    });
    const dialogRef = useRef<any>(null);

    const branding = getBranding();

    const handleVariantChange = (index: number, variant: ProductVariant) => {
        const newSelectedVariants = [...selectedVariants];
        newSelectedVariants[index] = variant;
        setSelectedVariants(newSelectedVariants);
    };

    const addToOrder = (meal: OrderMeal) => {
        const existingMealIndex = selectedMeals.findIndex(x => x.name === meal.name && x.description === meal.description);
        if (existingMealIndex !== -1) {
            const updatedMeals = [...selectedMeals];
            updatedMeals[existingMealIndex] = { ...updatedMeals[existingMealIndex], quantity: updatedMeals[existingMealIndex].quantity + 1 };
            setSelectedMeals(updatedMeals);
        } else {
            setSelectedMeals([...selectedMeals, { name: meal.name, description: meal.description, price: meal.price, quantity: 1 }]);
        }
    };

    const handleOpenModal = async () => {
        document.body.style.overflow = 'hidden';
        dialogRef.current.showModal();
    };

    const handleCloseModal = () => {
        setIngredientsToAdd([]);
        setIngredientsToRemove([]);
        setShowExtra(false);
        document.body.style.overflow = 'visible';
        dialogRef.current.close();
    };

    const handleSaveAndCloseModal = async () => {
        const variantDescription = selectedVariants.map((x, index) => {
            return `${meal.productVariants[index].name}: ${x.name}`
        }).join(' ') + ' '

        const removeDescription = ingredientsToRemove.length === 0 ? '' : `Utan ${ingredientsToRemove.map(x => x.name).join(', ')} `
        const addDescription = ingredientsToAdd.length === 0 ? '' : `Extra ${ingredientsToAdd.map(x => x.name).join(', ')}`

        const description = variantDescription + removeDescription + addDescription;
        const price = meal.price
            + ingredientsToAdd.reduce((sum, ingredient) => {
                return sum + ingredient.price;
            }, 0)
            + selectedVariants.reduce((sum, variant) => {
                return sum + variant.price;
            }, 0);
        const orderMeal: OrderMeal = { name: meal.name, description, price, quantity: 1 };

        addToOrder(orderMeal);

        handleCloseModal();
    };

    const handleRemoveIngredient = (ingredientToRemove: Ingredient) => {
        const isIngredientRemoved = ingredientsToRemove.find(x => x.id === ingredientToRemove.id);
        if (isIngredientRemoved) {
            setIngredientsToRemove(ingredientsToRemove.filter(x => x.id !== ingredientToRemove.id));
        } else {
            setIngredientsToRemove([...ingredientsToRemove, ingredientToRemove]);
        }
    };

    const handleAddIngredient = (ingredientToAdd: Ingredient) => {
        const isIngredientAdded = ingredientsToAdd.find(x => x.id === ingredientToAdd.id);
        if (isIngredientAdded) {
            setIngredientsToAdd(ingredientsToAdd.filter(x => x.id !== ingredientToAdd.id));
        } else {
            setIngredientsToAdd([...ingredientsToAdd, ingredientToAdd]);
        }
    };

    const [showExtra, setShowExtra] = useState(false);

    function toggleExtra()
    {
        setShowExtra((a) => !showExtra)
    }
    


    return (
        <>
            <button onClick={handleOpenModal} className="button round" style={{ backgroundColor: branding.primaryButtonColor, color: branding.primaryButtonTextColor }}>+</button>
            <dialog ref={dialogRef} className="dialog mainContainer">
                <div className="dialog-content">
                    <div className="dialog-body">
                        <ul className="dialog-list">
                            {meal.productVariants.map((variantGroup, groupIndex) => (
                                <li key={groupIndex} className="dialog-list-item">
                                    <h3>{variantGroup.name}</h3>
                                    <ul className="variant-list">
                                        {variantGroup.variants.map((variant, variantIndex) => (
                                            <li key={variantIndex} className="variant-item">
                                                <label className="variant-label">
                                                    <input
                                                        type="radio"
                                                        name={`variant-${groupIndex}`}
                                                        value={variant.name}
                                                        checked={selectedVariants[groupIndex].id === variant.id}
                                                        onChange={() => handleVariantChange(groupIndex, variant)}
                                                    />
                                                    {variant.name}
                                                </label>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            ))}
                        </ul>
                        <div className="dialog-list-item">
                            <h3>Ingredienser som ingår</h3>
                            <ul className="dialog-list">
                                {meal.ingredients.map((ingredient, index) => (
                                    <li key={index} className="list-item">
                                        <label className="checkbox-label">
                                            <input
                                                type="checkbox"
                                                checked={ingredientsToRemove.find(x => x.id === ingredient.id) !== undefined}
                                                onChange={() => handleRemoveIngredient(ingredient)}
                                            />
                                            {ingredient.name}
                                        </label>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className='dialog-list-item' style={showExtra ? { display: 'none' } : { }}>
                            <button onClick={toggleExtra}>Extra ingredienser</button>
                        </div>

                        <div className="dialog-list-item" style={showExtra ? {} : { display: 'none' }}>
                            <h3>Extra ingredienser</h3>
                            <ul className="dialog-list">
                                {ingredients.map((ingredient, index) => (
                                    <li key={index} className="list-item">
                                        <label className="checkbox-label">
                                            <input
                                                type="checkbox"
                                                checked={ingredientsToAdd.find(x => x.id === ingredient.id) !== undefined}
                                                onChange={() => handleAddIngredient(ingredient)}
                                            />
                                            {ingredient.name} &nbsp;
                                            {ingredient.price} kr
                                        </label>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <button className="primary-button" style={{ backgroundColor: branding.primaryButtonColor, color: branding.primaryButtonTextColor }} onClick={handleSaveAndCloseModal}>Lägg till</button>
                        <button className="secondary-button" onClick={handleCloseModal}>Avbryt</button>
                    </div>

                </div>
                <div className="dialog-bottom">

                </div>
            </dialog>
        </>
    );
};

export default OrderMealIngredientModal;
