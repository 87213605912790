import { onValue, ref } from 'firebase/database';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { database } from './firebase';
import { OrderStatusUpdate } from './types';
import dayjs, { Dayjs } from 'dayjs'; // Import dayjs for date manipulation
import './PlacedOrder.css';

interface Props {
    restaurantId: string;
}

const PlacedOrder: React.FC<Props> = ({ restaurantId }) => {
    const location = useLocation();
    const orderId = new URLSearchParams(location.search).get('order-id');

    const [order, setOrder] = useState<OrderStatusUpdate>({
        orderStatus: 'created',
        orderNumber: undefined,
        readyAt: dayjs(), // Default to current time
        createdAt: dayjs() // Default to current time
    });
    const [timeRemaining, setTimeRemaining] = useState<number | null>(null);

    useEffect(() => {
        const timer = setInterval(() => {
            updateTimeRemaining(order.readyAt);
        }, 60000); // Update every minute

        return () => clearInterval(timer);
    }, [order]);

    useEffect(() => {
        onValue(ref(database, `orders/${restaurantId.replace(/\./g, '')}/${orderId}`), (snapshot) => {
            const data = snapshot.val();
            if (data) {
                const readyAt = dayjs(data.readyAt * 1000);
                setOrder({ ...data, readyAt, createdAt: dayjs(data.createdAt * 1000) });

                if (data.orderStatus === 'accepted') {
                    updateTimeRemaining(readyAt);
                }
            }
        });
    }, []);

    function updateTimeRemaining(readyAt: Dayjs) {
        const diffMinutes = readyAt.diff(dayjs(), 'minute');
        setTimeRemaining(diffMinutes > 0 ? diffMinutes : 0);
    }

    function getOrderStatusMessage(order: OrderStatusUpdate) {
        switch (order.orderStatus) {
            case 'created':
                return <p>Din beställning har skickats. <br /><br />Inväntar <b>bekräftelse</b> från restaurangen</p>;
            case 'accepted':
                return (
                    <>
                        <p>Din beställning har <b>godkänts</b> av restaurangen.</p>
                        <div className="order-status-message">
                            {order.orderNumber}
                        </div>
                        <p className="order-ready">Order är klar om ca {timeRemaining} minuter</p>
                    </>
                );
            case 'finished':
                return (
                    <>
                        <p>Din beställning är <b>färdig</b>.</p>
                        <div className="order-status-message">
                            {order.orderNumber}
                        </div>
                    </>
                );
            case 'delivered':
                return (
                    <>
                        <p>Din beställning är <b>avklarad</b>.</p>
                        <div className="order-status-message">
                            {order.orderNumber}
                        </div>
                    </>
                );
            case 'declined':
                return (
                    <>
                        <p>Tyvärr kan inte beställningen genomföras.</p>
                        <div className="order-status-message">
                            {order.orderNumber}
                        </div>
                    </>
                );
            default:
                return <p>Något gick fel</p>;
        }
    }

    return (
        <>
            <div className="mainContainer">
                <div className="place-order-section">
                    <h1 style={{ textAlign: 'left', flex: 1, paddingLeft: '20px', marginTop: '20px' }}>Beställning mottagen!</h1>
                </div>
                <div className="order-container place-order-section">
                    {getOrderStatusMessage(order)}
                </div>
            </div>
        </>
    );
};

export default PlacedOrder;
