import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useMealContext } from './MealContext';
import { ContactInfo, OrderData, OrderMeal, OrderType } from './types';
import { doc, getDoc, setDoc, updateDoc } from '@firebase/firestore';
import { database, firestore } from './firebase';
import { Guid } from 'guid-typescript';
import dayjs from 'dayjs';
import { ref, set } from 'firebase/database';
import { toTimestamp } from './util';
import './Checkout.css';

interface Props {
    restaurantId: string;
}

const Checkout: React.FC<Props> = ({ restaurantId }) => {
    const navigate = useNavigate();
    const { selectedMeals, setSelectedMeals, getBranding } = useMealContext();
    const branding = getBranding();
    const [formData, setFormData] = useState<ContactInfo>({
        name: '',
        phoneNumber: '',
        email: ''
    });
    const [orderType, setOrderType] = useState<OrderType>('take-away');
    const [comments, setComments] = useState<string>('');
    const [deliveryTime, setDeliveryTime] = useState<number>(0);

    const handleDeliveryTimeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setDeliveryTime(parseInt(e.target.value));
    };

    const handleCommentsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setComments(e.target.value);
    };

    const handleQuantityChange = (name: string, quantity: number) => {
        const updatedMeals = selectedMeals.map(meal => {
            if (meal.name === name) {
                if (quantity === 0) {
                    return null;
                } else {
                    return { ...meal, quantity };
                }
            }
            return meal;
        }).filter(Boolean) as OrderMeal[];

        setSelectedMeals(updatedMeals);
    };

    const handleIncrement = (name: string) => {
        const updatedMeals = selectedMeals.map(meal => {
            if (meal.name === name) {
                return { ...meal, quantity: meal.quantity + 1 };
            }
            return meal;
        });
        setSelectedMeals(updatedMeals);
    };

    const handleDecrement = (name: string) => {
        const updatedMeals = selectedMeals.map(meal => {
            if (meal.name === name && meal.quantity > 0) {
                if (meal.quantity === 1) {
                    return null;
                } else {
                    return { ...meal, quantity: meal.quantity - 1 };
                }
            }
            return meal;
        }).filter(Boolean) as OrderMeal[];
        setSelectedMeals(updatedMeals);
    };

    const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    async function placeOrder(orderDocSnapshot: any, formattedDate: string, orderData: OrderData, orderDocRef: any) {
        const existingData = orderDocSnapshot.data();
        const existingOrders = existingData ? existingData[formattedDate] || [] : [];
        const updatedOrders = [...existingOrders, { ...orderData, createdAt: toTimestamp(orderData.createdAt) }];
        await updateDoc(orderDocRef, { [formattedDate]: updatedOrders });

        await set(ref(database, `orders/${restaurantId.replace(/\./g, '')}/created/${orderData.id}`), {
            orderStatus: orderData.orderStatus,
            orderNumber: orderData.orderNumber,
            createdAt: dayjs().unix()
        });

        setSelectedMeals([]);

        navigate(`/placed-order?order-id=${orderData.id}`);
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!orderType) {
            alert('Var god välj om du vill äta här eller ta med');
            return;
        }

        const orderData = {
            id: Guid.create().toString(),
            contactInfo: formData,
            orderType,
            meals: groupedMeals,
            totalPrice,
            comments,
            deliveryTime: dayjs().add(deliveryTime, 'minute').format('HH:mm'),
            orderStatus: 'created',
            orderNumber: 0,
            createdAt: dayjs()
        } as OrderData;

        try {
            const formattedDate = dayjs().format('YYYY-MM-DD');
            const orderDocRef = doc(firestore, 'orders', restaurantId);

            const orderDocSnapshot = await getDoc(orderDocRef);
            if (orderDocSnapshot.exists()) {
                await placeOrder(orderDocSnapshot, formattedDate, orderData, orderDocRef);
            } else {
                await setDoc(orderDocRef, {});
                await placeOrder(orderDocSnapshot, formattedDate, orderData, orderDocRef);
            }
        } catch (error) {
            console.error('Error updating document: ', error);
            alert('An error occurred while updating the order. Please try again later.');
        }
    };

    const groupedMeals = selectedMeals.reduce((acc: OrderMeal[], meal) => {
        const existingMeal = acc.find(item => item.name === meal.name && item.description === meal.name);
        if (existingMeal) {
            existingMeal.quantity += meal.quantity;
        } else {
            acc.push({ ...meal });
        }
        return acc;
    }, []);

    const totalPrice = groupedMeals.reduce((total, meal) => total + (meal.quantity * meal.price), 0);

    return (
        <>
        <div className="mainContainer">
            <div className="checkout-section">
                <Link to="/" className="link-button" style={{ backgroundColor: branding.primaryButtonColor, color: branding.primaryButtonTextColor }}>Tillbaka till Menyn</Link>
            </div>
            <div className="checkout-section">
                <div className="checkout-menu-category">
                    <ul>
                        {groupedMeals.map((meal, index) => (
                            <li key={index} className="checkout-menu-item">
                                <div className="checkout-menu-item-row">
                                    <div className="checkout-menu-item-info">
                                    <div className="checkout-name">
                                        {meal.name}
                                    </div>
                                    <div className="checkout-description">
                                        {meal.description}                                
                                    </div>
                                    </div>
                                    <div className="checkout-menu-item-price">
                                    {meal.price * meal.quantity} kr
                                    </div>
                                </div>
                                <div className="checkout-menu-item-row">
                                    <div className="checkout-menu-add-remove">
                                        <div className="checkout-menu-add-remove-remove">
                                            <button onClick={() => handleDecrement(meal.name)} className="button round">-</button>
                                        </div>
                                        <div className="checkout-menu-add-remove-amount">
                                            <input
                                                type="number"
                                                min="0"
                                                value={meal.quantity}
                                                onChange={(e) => handleQuantityChange(meal.name, parseInt(e.target.value))}
                                                className="checkout-quantity-input"
                                            />
                                        </div>
                                        <div className="checkout-menu-add-remove-add">
                                            <button onClick={() => handleIncrement(meal.name)} className="button round">+</button>
                                        </div>                                        
                                    </div>
                            </div>
                            </li>
                        ))}
                    </ul>
                    <div className="checkout-total-price">Att betala: {totalPrice} kr</div>
                </div>
                
            </div>
            

                <div className="checkout-section">
                    <button onClick={e => {
                        e.preventDefault();
                        setOrderType('eat-in');
                    }} style={{ backgroundColor: orderType === 'eat-in' ? branding.primaryButtonColor : branding.buttonColor, color: orderType === 'eat-in' ? branding.primaryButtonTextColor : branding.buttonTextColor }}>Ät här</button>&nbsp;
                    <button onClick={e => {
                        e.preventDefault();
                        setOrderType('take-away');
                    }} style={{ backgroundColor: orderType === 'take-away' ? branding.primaryButtonColor : branding.buttonColor, color: orderType === 'take-away' ? branding.primaryButtonTextColor : branding.buttonTextColor }}>Ta med</button>&nbsp;
                    <select value={deliveryTime} onChange={handleDeliveryTimeChange} className="select-delivery">
                        <option value={0}>Snarast</option>
                        <option value={30}>Om 30 min</option>
                        <option value={45}>Om 45 min</option>
                        <option value={60}>Om 1 timme</option>
                        <option value={120}>Om 2 timmar</option>
                        <option value={180}>Om 3 timmar</option>
                    </select>
                </div>
                <div className="checkout-section">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label className="form-label">Namn</label>
                            <input type="text" name="name" value={formData.name} onChange={handleFormChange} className="form-input" required />
                        </div>
                        <div className="form-group">
                            <label className="form-label">Telefonnummer</label>
                            <input type="text" name="phoneNumber" value={formData.phoneNumber} onChange={handleFormChange} className="form-input" required />
                        </div>
                        <div className="form-group">
                            <label className="form-label">E-post</label>
                            <input type="email" name="email" value={formData.email} onChange={handleFormChange} className="form-input" required />
                        </div>
                        <div className="form-group">
                            <label className="form-label">Kommentarer</label>
                            <textarea name="comments" value={comments} onChange={handleCommentsChange} className="form-input comments" />
                        </div>
                        <div className="form-actions">                            
                            <button type="submit" className="submit-button" style={{ backgroundColor: branding.primaryButtonColor, color: branding.primaryButtonTextColor }}>Beställ</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default Checkout;
