import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useMealContext } from './MealContext';
import dayjs from 'dayjs';
import { getSwedishDayOfWeek } from './util';
import OrderMealIngredientModal from './OrderMealIngredientModal';
import './Menu.css';

const Menu: React.FC = () => {
  const { selectedMeals, restaurant } = useMealContext();

  const getOpenTime = () => {
    const dayOfWeek = getSwedishDayOfWeek();
    if (restaurant?.contact.openingHours[dayOfWeek].closed) {
      return 'Stängt';
    }
    return `${dayjs(restaurant?.contact.openingHours[dayOfWeek].open).format('HH:mm')} - ${dayjs(restaurant?.contact.openingHours[dayOfWeek].close).format('HH:mm')}`;
  };

  if (!restaurant) return <div>Loading...</div>;

  return (

    <div className="mainContainer">      
      <div className="">
        <section className="section-info">
          <h2>Adress</h2>
          <p>{restaurant.contact.address}</p>
          <h2>Öppet idag</h2>
          <p>{getOpenTime()}</p>
        </section>
        <div className="menu-items">
          {restaurant.menu.map((menu, index) => (
            <div key={index} className="menu-category">
              <h2>{menu.name}</h2>
              <ul>
                {menu.meals.map((meal, mealIndex) => {
                  const selectedMeal = selectedMeals.find(selectedMeal => selectedMeal.name === meal.name);
                  const totalQuantity = selectedMeals.reduce((sum, x) => {
                    return x.name === meal.name ? sum + x.quantity : sum;
                  }, 0);
                  const quantity = selectedMeal ? totalQuantity : 0;
                  return (
                    <li key={mealIndex} className="menu-item">
                      <div className="menu-item-row">                        
                        <div className="menu-item-info">
                          <div className="name">
                            {meal.name}
                          </div>
                          <div className="menu-item-info-description">{meal.ingredients.map(e => e.name).join(', ')}</div>
                        </div>
                        <div className="menu-item-price">
                          {meal.price} kr
                        </div>
                        <div className="menu-item-other">
                        {quantity > 0 && (
                          <button className="button-quanitty button round">{quantity}</button>
                        )}
                        <OrderMealIngredientModal meal={meal} />
                        </div>                        
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <div className="fixed-footer">
        <Link to="/checkout" className="footer-link">
          <FontAwesomeIcon icon={faShoppingCart} className="footer-icon" />
          Tryck här för att beställa: {selectedMeals.reduce((acc, meal) => acc + meal.quantity, 0)}
        </Link>
      </div>
    </div>
  );
};

export default Menu;
