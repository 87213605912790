import React from 'react';
import dayjs from 'dayjs';
import { useMealContext } from './MealContext';
import { getSwedishDayOfWeek, swedishDays } from './util';
import Header from './Header';
import './OpeningTimeWrapper.css';

type OpeningTimeWrapperProps = {
    children: React.ReactNode;
};

const OpeningTimeWrapper: React.FC<OpeningTimeWrapperProps> = ({ children }) => {
    const { restaurant } = useMealContext();

    if (!restaurant) return <div>Loading...</div>;

    const dayOfWeek = getSwedishDayOfWeek();
    const openingTime = restaurant?.contact.openingHours[dayOfWeek];

    const open = dayjs().set('hours', openingTime.open.get('hours')).set('minutes', openingTime.open.get('minutes'));
    const close = dayjs().set('hours', openingTime.close.get('hours')).set('minutes', openingTime.close.get('minutes'));
    const withinOpeningHours = dayjs().isAfter(open) && dayjs().isBefore(close);
    const isOpen = !openingTime.closed && withinOpeningHours;

    if (!isOpen) {
        return (
            <>
                <Header />
                <span className="closed-message">
                    {restaurant.name} är för närvarande stängd. Välkommen tillbaka under våra öppettider.
                </span>
                <div className="opening-hours">
                    {restaurant.contact.openingHours.map((openingTime, index) => (
                        <div key={index} className="status-container">
                            <label className="day-label">{swedishDays[index]}:</label>
                            <span>{openingTime.closed ? "Stängd" : "Öppen"}</span>
                            <span style={{ marginLeft: "5px" }}>
                                {!openingTime.closed && `${openingTime.open.format("HH:mm")}-${openingTime.close.format("HH:mm")}`}
                            </span>
                        </div>
                    ))}
                </div>
            </>
        );
    }

    return <>{children}</>;
};

export default OpeningTimeWrapper;
