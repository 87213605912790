// App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Menu from './Menu';
import Checkout from './Checkout';
import { MealProvider } from './MealContext'; // Import the MealProvider
import PlacedOrder from './PlacedOrder';
import OpeningTimeWrapper from './OpeningTimeWrapper';
import Header from './Header';
import CookieConsent from './CookieConsent';

const App: React.FC = () => {

  const restaurantId = window.location.hostname;

  return (
    <Router>
      <MealProvider restaurantId={restaurantId}>
        <OpeningTimeWrapper>
          <Header />
          <Routes>
            <Route path="/" element={
              <Menu />
            } />
            <Route path="/checkout" element={<Checkout restaurantId={restaurantId} />} />
            <Route path="/placed-order" element={<PlacedOrder restaurantId={restaurantId} />} />
          </Routes>
          <CookieConsent></CookieConsent>
        </OpeningTimeWrapper>
      </MealProvider>
    </Router>
  );
};

export default App;
