import React, { useState } from "react";
import { useMealContext } from "./MealContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons';

const Header: React.FC = () => {

    const { restaurant } = useMealContext()
    const [navClass, setNavClass] = useState("header");

    const toggleTopNav = () => {
        if (navClass === "header") {
            setNavClass("header responsive");
        } else {
            setNavClass("header");
        }
    };

    if (!restaurant) return <div>Loading...</div>;

    return (
        <header className={navClass} id="header">
            <a href="" className="active">{restaurant.name}</a>
            <a href="/">Meny</a>
            <a href="/checkout">Beställ</a>
            <a href="javascript:void(0);" className="icon" onClick={toggleTopNav}>
                <FontAwesomeIcon icon={faBars} />
            </a>
        </header>
    );
}

export default Header;
